import { useState } from 'react'
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3"
import axios from 'axios'
import { useSearchParams } from "react-router-dom";
import { useEffect } from 'react';
import Config from './Config';


function Uploader() {
    //Declare search params to process tid and timestamp (displayed as localhost:3000/?TID=123222&TS=1&V=123456) - Additionally create a timeout variable that sets a 15 minute time out 
    let [searchParams, setSearchParams] = useSearchParams();
    let tid = searchParams.get("TID")
    let ZetcomMultimediaUuid = searchParams.get("zetcom-multimedia-uuid") || '';

    let timestamp = searchParams.get("TS")
    let verification = searchParams.get("V")
    const timeout = parseFloat(timestamp) + parseFloat(15 * 1000 * 60)  //Timeout value (will not let user upload or load the page after timestamp has passed 15 minutes)

    //some config variables for aws connection to work
    var aws_region = Config.aws_region
    var s3_bucket = Config.s3_bucket
    // check if endpoint has trailing slash, if yes remove it
    if (Config.sts_token_service_endpoint.endsWith('/')) {
        Config.sts_token_service_endpoint = Config.sts_token_service_endpoint.slice(0, -1);
    }
    if (Config.web_socket_endpoint.endsWith('/')) {
        Config.web_socket_endpoint = Config.web_socket_endpoint.slice(0, -1);
    }
    // Allow only image types
    const allowedMimes = Config.allowed_mime_types;
    // get the allowed file types from the allowedMimes array , get the value after `/` and add a `.` before it. e.g. `jpeg` becomes `.jpeg
    const allowedTypes = allowedMimes.map((type) => `${type.split('/')[1]}`);
    // Maximum file size in bytes
    const maxFileSize = Config.max_file_size;
    // convert bytes to megabytes and get the nearest 2 decimal place
    const maxFileSizeInMB = (maxFileSize / 1048576).toFixed(2);
    //state to update the uploading file names
    const [fileNames, setFilenames] = useState([]);

    //State to use controlled input to keep file data persistent 
    const [fileData, setFileData] = useState([]);
    const setFiles = (e) => {
        const files = Array.from(e.target.files);
        const fileNamesArray = files.map((fileItem) => fileItem.name);
        setFileData(files);
        setFilenames(fileNamesArray);
        setTotalFiles(fileNamesArray.length);
        // set setWebSocketStatus to default value false
        setWebSocketStatus(fileNamesArray.map(() => false));
        // Reset the input value
        e.target.value = '';
    };
    // state to monitor progress
    const [uploading, setUploading] = useState([]);
    const [uploaded, setUploaded] = useState([]);
    const [scanningInProgress, setScanningInProgress] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [webSocketStatus, setWebSocketStatus] = useState([]);
    const [totalFiles, setTotalFiles] = useState(0);
    const [totalScannedFiles, setTotalScannedFiles] = useState(0);
    const [isFailed, setIsFailed] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    // uncomment below for debugging
    // useEffect(() => {
    //     console.log("is submit: ",isSubmit);
    // }, [isSubmit]);
    // useEffect(() => {
    //     console.log("total scanned files: ",totalScannedFiles);
    // }, [totalScannedFiles]);
    // useEffect(() => {
    //     console.log("web socket status: ",webSocketStatus);
    // }, [webSocketStatus]);
    // useEffect(() => {
    //     console.log("total files: ",totalFiles);
    // }, [totalFiles]);
    // useEffect(() => {
    //     console.log("filenames: ",fileNames);
    // }, [fileNames]);

    //State to store STS Token
    const [stsToken, setSTSToken] = useState('');
    //state to store status of STS Token request
    const [stsTokenStatus, setSTSTokenStatus] = useState();
    //TODO - Set up axios and assign response to variable
    //STS lInk: https://9z1ro2qaif.execute-api.ap-southeast-1.amazonaws.com/prod/sfu/sts_token?V=aaaa&TID=aaaaa&TS=aaa
    var stsTokenVars;

    useEffect(() => {
        async function STSToken() {
            try {
                setSTSTokenStatus("authenticating");
                stsTokenVars = await axios.get(Config.sts_token_service_endpoint + '?TS=' + timestamp + '&TID=' + tid + '&V=' + encodeURIComponent(verification))
                setSTSToken(stsTokenVars.data.Credentials);
                setSTSTokenStatus("sts_success");
            } catch (error) {
                setSTSTokenStatus("sts_error");
                console.error('Error fetching STS token:', error);
                // You can handle the error further or show a message to the user
            }
        }
        STSToken();
    },
        []);

    // set the specific file name to the state
    const updateFilename = (key, value) => {
        setFilenames(prevFileNames => {
            const newFileNames = [...prevFileNames];
            newFileNames[key] = value;
            return newFileNames;
        });
    };
    // set the specific scanning progress 
    const updateScanningInProgress = (key, value) => {
        setScanningInProgress(prevState => {
            const newScanningInProgress = [...prevState];
            newScanningInProgress[key] = value;
            return newScanningInProgress;
        });
    };
    // set the specific uploaded status
    const updateUploaded = (key, value) => {
        setUploaded(prevState => {
            const newUploaded = [...prevState];
            newUploaded[key] = value;
            return newUploaded;
        });
    };
    // set the specific uploading status
    const updateUploading = (key, value) => {
        setUploading(prevState => {
            const newUploading = [...prevState];
            newUploading[key] = value;
            return newUploading;
        });
    };
    // set the specific websocket status
    const updateWebSocketStatus = (key, value) => {
        setWebSocketStatus(prevState => {
            const newWebSocketStatus = [...prevState];
            newWebSocketStatus[key] = value;
            return newWebSocketStatus;
        });
    };

    // websocket connection - to receive scan result
    const webSocket = (filename, file_number) => {
        const socket = new WebSocket(Config.web_socket_endpoint + '?file_key=' +  encodeURIComponent(filename));
        console.log("socket created");
        socket.onopen = () => {
            console.log('Connected to websocket');
            updateScanningInProgress(file_number, true);
        };
        socket.onmessage = (event) => {
            console.log("response received");
            updateUploaded(file_number, false);
            // convert string to json
            var data = JSON.parse(event.data);
            const findings = data.scanning_result.Findings;
            // check if finding is empty [] 
            if (findings.length === 0) {
                // 
                updateFilename(file_number, filename + " ✅ ");
            } else {
                updateFilename(file_number, filename + " ❌ -Upload Blocked ");
                setIsFailed(true);
            }
        };
        socket.onclose = () => {
            console.log('Disconnected from websocket');
            updateWebSocketStatus(file_number, true);
            updateScanningInProgress(file_number, false);
            setTotalScannedFiles(prevTotalScannedFiles => prevTotalScannedFiles + 1);
        };


        socket.onerror = (error) => {
            console.log("error in websocket");
            console.log('Error: ' + error.message);
        };
    };

    //Define function that receives file data and uploads it to S3. Passes fileData from fileToUpload function (inspired by AWS Documentation)
    const UploadToS3 = async (fileData) => {

        const REGION = aws_region; //REGION

        const s3 = new S3Client({
            region: REGION,
            credentials: {
                accessKeyId: stsToken.AccessKeyId,
                secretAccessKey: stsToken.SecretAccessKey,
                sessionToken: stsToken.SessionToken
            }
        });

        const BucketName = s3_bucket; //BUCKET_NAME

        const files = fileData;
        const fileUploadNames = []

        //Logic to loop through all items and upload them to S3
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            updateUploading(i, true);
            if (!allowedMimes.includes(file.type)) {
                // alert("Only images (jpeg, png, gif, plain, zip, pdf) are allowed.");
                fileUploadNames[i] = file.name + " ❌ - File not Supported";
                updateFilename(i, fileUploadNames[i]);
                updateUploading(i, false);
                setTotalScannedFiles(prevTotalScannedFiles => prevTotalScannedFiles + 1);
                setIsFailed(true);
                continue;
            }
            if (file.size > maxFileSize) {
                // alert("File size limit exceeded. Maximum file size is 5 MB.");
                fileUploadNames[i] = file.name + " ❌ - File size limit exceeded";
                updateFilename(i, fileUploadNames[i]);
                updateUploading(i, false);
                setTotalScannedFiles(prevTotalScannedFiles => prevTotalScannedFiles + 1);
                setIsFailed(true);
                continue;
            }
            let fileName = file.name;
            fileName = fileName.replace(/(\.[\w\d_-]+)$/i, '-' + tid + '$1');
            // console.log(fileName)
            const photoKey = fileName;
            const uploadParams = {
                Bucket: BucketName,
                Key: photoKey,
                Body: file,
                ContentType: file.type, // Add ContentType
                Tagging: `TID=${tid}&zetcom-multimedia-uuid=${ZetcomMultimediaUuid}` // Add the TID as a tag
            };


            try {
                const data = await s3.send(new PutObjectCommand(uploadParams));
                // console.log("Successfully uploaded photo: " + fileName);
                updateUploading(i, false);
                updateUploaded(i, true);
                // create a new web socket connection for each file uploaded
                webSocket(fileName, i);
            }
            catch (err) {
                fileUploadNames[i] = fileName + " ❌ - Upload Failed";
                updateFilename(i, fileUploadNames[i]);
                updateUploading(i, false);
                setTotalScannedFiles(prevTotalScannedFiles => prevTotalScannedFiles + 1);
                setIsFailed(true);
                console.log(err)

            }
        }

    }


    //Define Function to collect and process "File Data" from the webpage
    const fileToUpload = async (file) => {
        if (Date.now() < timeout) {
            //Stop page from refreshing when form submits
            file.preventDefault();
            setIsFailed(false);
            setTotalScannedFiles(0);
            setIsSubmit(true);
            //Store file list in json array "files"
            const files = fileData;
            // console.log(files);

            //Create array for file names
            const fileNames = [];

            for (let file of files) {
                fileNames.push(file.name);
            }
            // set the file names before uploading
            setFilenames(fileNames);

            //Call Function to Upload stuff to S3
            await UploadToS3(files);
        }
        else {
            alert("Page timed out, reload and try again!");
        }
    };

    const onDropHandler = (e) => {
        e.preventDefault();
        const droppedFiles = Array.from(e.dataTransfer.files);
        setFileData((prevFileData) => [...prevFileData, ...droppedFiles]);
        const fileNamesArray = droppedFiles.map((fileItem) => fileItem.name);
        setFilenames((prevFileNames) => [...prevFileNames, ...fileNamesArray]);
        // set the total files
        setTotalFiles((prevTotalFiles) => prevTotalFiles + droppedFiles.length);
        // set scan status to false all
        setWebSocketStatus(fileNamesArray.map(() => false));
    };

    const removeFile = (event, indexToRemove) => {
        // Prevent form submission when the trash icon is clicked
        event.preventDefault();
        setIsSubmit(false);
        setTotalFiles((prevTotalFiles) => prevTotalFiles - 1);
        setWebSocketStatus((prevWebSocketStatus) => prevWebSocketStatus.filter((_, index) => index !== indexToRemove));
        setFileData((prevFileData) => prevFileData.filter((_, index) => index !== indexToRemove));
        setFilenames((prevFileNames) => prevFileNames.filter((_, index) => index !== indexToRemove));
    };
    // Use useEffect to set isProcessing based on conditions
    useEffect(() => {
        if (isSubmit && totalFiles !== totalScannedFiles && webSocketStatus.length > 0) {
            setIsProcessing(true);
        }
    }, [isSubmit, totalFiles, totalScannedFiles, webSocketStatus]);

    // Ensures presence of 3 variables for file upload
    // if still processing do not expire the page
    if ((isProcessing || Date.now() < timeout) && stsToken && tid && timestamp) {
        return (
            <div className="flex justify-center items-center bg-customgray min-h-screen">
                <div className="flex flex-col items-start">
                    <img
                        src="https://www.nhb.gov.sg/-/media/nhb/images/nhb2017/home/header/logo-nhb.png"
                        alt="Logo"
                        className="mb-8 h-17 w-60 mt-0"
                    />
                    <div className="bg-white rounded-lg shadow-xl lg:p-16 p-10 lg:px-40 px-10">

                        {/* <div className="mb-0">
                        <p><strong>TID:</strong> {tid}</p><p>
                        <strong>Timestamp:</strong> {timestamp}</p>
                    </div> */}
                        <form className="flex flex-col items-center justify-center" onSubmit={fileToUpload}>
                            <div className="mb-0 mt-2">
                                <label
                                    className="block text-center text-gray-700 text-5xl font-bold mb-2 font-calibri"
                                    htmlFor="file-upload">File Upload</label>
                                <input
                                    type="file"
                                    id="file-upload"
                                    name="file-upload"
                                    className="hidden"
                                    onChange={setFiles}
                                    multiple
                                />
                                {!isSubmit && (
                                    <div
                                        className={`relative mt-3 border-dashed border-2 rounded-md border-gray-300 ${!isSubmit ? 'cursor-pointer' : ''} p-10 ${fileNames && fileNames.length > 0 ? 'h-auto' : 'h-96'
                                            } flex justify-center items-center inset-0 bg-gray-200`}
                                        // add if isSubmit is true, then add the onDropHandler
                                        onDragOver={(e) => e.preventDefault()}
                                        onDragLeave={(e) => e.preventDefault()}
                                        onDrop={!isSubmit ? (e) => onDropHandler(e) : () => { }}>
                                        <div className="text-center">
                                            <i className="fa fa-photo text-4xl mb-2 text-blue-400" aria-hidden="true"></i>
                                            {!isSubmit && (
                                                <p className="text-gray-600 font-bold text-3xl font-calibri">
                                                    Drag and drop your files here or
                                                    <label
                                                        htmlFor="file-upload"
                                                        className="cursor-pointer text-indigo-500 hover:underline font-calibri">&nbsp;Browse</label>
                                                </p>
                                            )}
                                            <p className="text-md text-gray-500 font-calibri">
                                                Supported Formats: {allowedTypes.join(', ')}. <br></br>
                                                Max file size: {maxFileSizeInMB} MB
                                            </p>
                                        </div>
                                    </div>
                                )}
                                {fileNames &&
                                    fileNames.map((fileName, index) => {
                                        let fileNameParts;

                                        if (isSubmit && totalFiles === totalScannedFiles && webSocketStatus.length > 0) {
                                            fileNameParts = [
                                                fileName.slice(0, 53),
                                                fileName.slice(53 + (fileName.charAt(53) === ' ' ? 1 : 0)),
                                            ];
                                        } else {
                                            fileNameParts = [
                                                fileName.slice(0, 30),
                                                fileName.slice(30 + (fileName.charAt(30) === ' ' ? 1 : 0)),
                                            ];
                                        }

                                        return (
                                            <div className="mt-3 flex justify-center" key={index}>
                                                <div className="border border-green-500 rounded-md p-1 px-1 text-left w-full overflow-auto">
                                                    <div className="flex items-start">
                                                        <p className="text-black flex-grow break-all">
                                                            {fileNameParts.map((part, i) => (
                                                                <span key={i}>
                                                                    {part}
                                                                </span>
                                                            ))}
                                                        </p>
                                                        {uploaded[index] && (
                                                            <div className="ml-2 text-sm font-semibold text-gray-700 self-start"></div>
                                                        )}
                                                    </div>
                                                    {uploading[index] && (
                                                        <div className="mt-2 text-sm font-semibold text-gray-700">
                                                            <i className="fa fa-spinner fa-spin text-blue-500"></i> File is uploading...
                                                        </div>
                                                    )}
                                                    {scanningInProgress[index] && (
                                                        <div className="mt-2 text-sm font-semibold text-gray-700">
                                                            <i className="fa fa-spinner fa-spin text-blue-500"></i> Scanning in progress...
                                                        </div>
                                                    )}
                                                </div>
                                                {!isSubmit && (
                                                    <button
                                                        className="text-red-500 font-bold py-1 px-2 ml-2 rounded"
                                                        onClick={(event) => removeFile(event, index)}
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </button>
                                                )}
                                            </div>
                                        );
                                    })}
                                {fileNames && fileNames.length > 0 && (
                                    <div className="flex flex-col justify-center items-center mt-2">
                                        {isSubmit && totalFiles !== totalScannedFiles && webSocketStatus.length > 0 ? (
                                            <p><i className="fa fa-spinner fa-spin text-blue-500"></i>&nbsp;Processing...</p>
                                        ) : (
                                            <>
                                                {!isSubmit && (
                                                    <button
                                                        type="submit"
                                                        className="bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-48 rounded"
                                                    >
                                                        Upload
                                                    </button>
                                                )}
                                                {isSubmit && totalFiles === totalScannedFiles && webSocketStatus.length > 0 && (
                                                    <>
                                                        {isFailed ? (
                                                            <>
                                                                <p className="text-center">Transaction completed. Please check the following file(s) that have not been uploaded.<br /> You may now close this window.</p>
                                                            </>
                                                        ) : (
                                                            <p className="text-center">Transaction completed, you may now close this window.</p>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    } else if (stsTokenStatus === "authenticating") {
        return (
            <div>
                <br></br>
                <div className="container mx-auto">
                    <h2 className="mx-auto text-center" style={{ width: "230px" }}>Acquiring Credentials...</h2>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <br></br>
                <div className="container mx-auto">
                    <h2 className="mx-auto text-center" style={{ width: "230px" }}>Page Not Available</h2>
                </div>
            </div>
        )
    }
}

export default Uploader