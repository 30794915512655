const Config = {
  // for dev purposes only | uncomment this and comment the production config below
    // aws_region: "ap-southeast-1",
    // s3_bucket: "my-sunreaver-droppoint-bucket",
    // sts_token_service_endpoint: "https://vdk9q5d7hg.execute-api.ap-southeast-1.amazonaws.com/dev/sfu/sts_token",
    // web_socket_endpoint: "wss://lrtn48sudl.execute-api.ap-southeast-1.amazonaws.com/dev",

  // for production purposes 
    aws_region: "ap-southeast-1",
    s3_bucket: "my-sunreaver-droppoint-bucket",
    sts_token_service_endpoint: "https://<hash>.execute-api.ap-southeast-1.amazonaws.com/dev/sfu/sts_token",
    web_socket_endpoint: "wss://<hash>.execute-api.ap-southeast-1.amazonaws.com/dev",
    allowed_mime_types: ["image/png","image/gif","text/plain","application/zip","image/tif","application/postscript","audio/x-aiff","image/x-adobe-dng","application/postscript","audio/flac","image/jpeg","audio/mpeg","video/mpeg","application/pdf","image/svg+xml","image/tiff","audio/wav","application/octet-stream","application/x-zip-compressed"],
    max_file_size: 4294967296 // 4096mb(4gb) in bytes(binary)
  };
  
  export default Config;
  