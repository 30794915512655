import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import Topnav from './components/ui/Topnav'
import Uploader from './components/upload/Uploader'
import Process from './components/upload/Process'


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <App />
    <Routes>
      <Route path="/" element ={<Uploader />}>
        <Route path=":tid" />
      </Route>
      <Route exact path="upload" element={<Process />} />
      
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
